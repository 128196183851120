img {max-width: 100% ;} 

/* disable css animations */
.touch .animated {
	-webkit-animation-duration: 0;
	animation-duration: 0;
	-webkit-animation-delay: 0;
	animation-delay: 0;
} 
/* disable background-size:cover */
.touch #container_header, .touch #container_top2_modules, .touch #container_bottom1_modules, .touch #container_bottom2_modules {
	-webkit-background-size: 	auto !important;
	-moz-background-size: 		auto !important;
	-o-background-size: 		auto !important;
	background-size: 			auto !important;
	background-repeat: repeat;
	background-attachment: scroll;
}

/* #Tablet (Landscape)
================================================== */

@media only screen and (max-width: 1024px) {

.wrapper960 {
	width:100% !important;
}
.backgrounds .content_background {
	width: 100% !important;
	padding: 10px 0;
}

.wrapper960, .content_background, #header {margin: 0 auto;}

/* Hide default hornav menu */
#hornav, #socialmedia {display:none !important;}
/* Show mobile hornav menu */
.slicknav_menu {display:block;}

.header-1, .header-2 {display:none;}

}

/* #Tablet (Portrait)
================================================== */
    
@media only screen and (max-width: 959px) {

}

/* #Mobile (Landscape)
================================================== */

@media only screen and (max-width: 767px) {

.sidecol_a, .sidecol_b, .items-row .item {
	width:100% !important;
}
.backgrounds .sidecol_a, .backgrounds .sidecol_b {display:none;}
#content_remainder {
	width: 100% !important;
}
.top-1, .top-2, .top-3, .contenttop, .contentbottom, .bottom-1, .bottom-2, .base-1, .base-2 {
	width:100% !important;
}
.row-fluid [class*="span"] {margin-left:0;}

#socialmedia {display:none;}

}

/*  #Mobile (Portrait)
================================================== */

    /* Note: Design for a width of 320px */

@media only screen and (max-width: 440px) {

#content_remainder, .items-row .item {
    width: 100% !important;
}
.row-fluid [class*="span"] {margin-left:0;}
.top-1, .top-2, .top-3, .contenttop, .contentbottom, .bottom-1, .bottom-2, .base-1, .base-2, .sidecol_a, .sidecol_b {
	width:100% !important;
}
#container_main .wrapper960 {
    margin-top: 0px;
}
#footermenu {
    float: left;
}

}


